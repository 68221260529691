<template>
  <div class="stat-container">
    <div class="stat-tab">
      <el-tabs type="border-card" v-model="tabName" @tab-click="changeTab">
        <!-- 防火巡检 tab-->
        <el-tab-pane label="防火巡检" v-loading="loading1" name="inspect">
          <div class="header">
            <el-form :inline="true" :model="searchInspect" class="demo-form-inline">
              <el-form-item label="核查人">
                <el-input v-model="searchInspect.checkPerson" placeholder="请输入核查人" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item label="巡查人">
                <el-input v-model="searchInspect.patrolPerson" placeholder="请输入巡查人" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="initFireInpect">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="inspectList" stripe style="width: 100%" height="100%">
              <el-table-column prop="checkResult" label="检查结果" show-overflow-tooltip fixed width="150" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="default" @click="open('NFC',scope.row)" v-show="scope.row.checkResult===0">自我检查合格</el-button>
                  <el-button type="text" size="default" @click="open('NFC',scope.row)" v-show="scope.row.checkResult===1">自我检查不合格</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="checkTime" label="核查时间" show-overflow-tooltip width="120" align="center">
                <template slot-scope="scope">
                  {{scope.row.checkTime|timeFormat}}
                </template>
              </el-table-column>
              <el-table-column prop="patrolPerson" label="巡查人" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="checkPerson" label="核查人" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="safeHead" label="安全主管" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="isFire" label="用火、用电有无违章情况" show-overflow-tooltip width="180" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isFire===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isFire===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isExit" label="安全出口、安全疏散通道是否畅通" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isExit===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isExit===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isLight" label="安全疏散疏散指示标志、应急照明是否完好" show-overflow-tooltip width="300" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isLight===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isLight===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isFlag" label="消防设施、器材和消防安全标志是否到位、完整" show-overflow-tooltip width="320" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isFlag===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isFlag===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isDoor" label="常闭式防火门是否处于关闭状态" show-overflow-tooltip width="220" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isDoor===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isDoor===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isThing" label="防火卷帘下是否堆放物品影响使用" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isThing===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isThing===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isPerson" label="消防安全重点部位的人员在岗情况" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isPerson===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isPerson===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isOthers" label="其他消防安全情况" show-overflow-tooltip width="150" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isOthers===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isOthers===0">是</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeInspectPage" :current-page.sync="inspectPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="inspectTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- NFC巡检 tab -->
        <el-tab-pane label="NFC巡检" v-loading="loading2" name="NFC">
          <div class="header">
            <el-form :inline="true" :model="searchNfc" class="demo-form-inline">
              <el-form-item label="核查人">
                <el-input v-model="searchNfc.checkPerson" placeholder="请输入核查人" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item label="巡查人">
                <el-input v-model="searchNfc.patrolPerson" placeholder="请输入巡查人" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="initNFC">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="NFCList" stripe style="width: 100%" height="100%">
              <el-table-column prop="checkResult" label="检查结果" show-overflow-tooltip fixed width="150" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===0">自我检查合格</el-button>
                  <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===1">现场整改</el-button>
                  <el-button type="text" size="default" @click="open('result',scope.row)" v-show="scope.row.checkResult===2">限期整改</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="checkTime" label="核查时间" show-overflow-tooltip width="120" align="center">
                <template slot-scope="scope">
                  {{scope.row.checkTime|timeFormat}}
                </template>
              </el-table-column>
              <el-table-column prop="patrolPerson" label="巡查人" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="checkPerson" label="核查人" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="safeHead" label="安全主管" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="isFire" label="用火、用电有无违章情况" show-overflow-tooltip width="180" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isFire===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isFire===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isExit" label="安全出口、安全疏散通道是否畅通" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isExit===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isExit===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isLight" label="安全疏散疏散指示标志、应急照明是否完好" show-overflow-tooltip width="300" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isLight===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isLight===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isFlag" label="消防设施、器材和消防安全标志是否到位、完整" show-overflow-tooltip width="320" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isFlag===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isFlag===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isDoor" label="常闭式防火门是否处于关闭状态" show-overflow-tooltip width="220" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isDoor===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isDoor===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isThing" label="防火卷帘下是否堆放物品影响使用" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isThing===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isThing===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isPerson" label="消防安全重点部位的人员在岗情况" show-overflow-tooltip width="240" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isPerson===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isPerson===0">是</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="isOthers" label="其他消防安全情况" show-overflow-tooltip width="150" align="center">
                <template slot-scope="scope">
                  <el-tag type="success" size="normal" v-show="scope.row.isOthers===1">否</el-tag>
                  <el-tag type="danger" size="normal" v-show="scope.row.isOthers===0">是</el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeNFCPage" :current-page.sync="nfcPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="nfcTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 消防控制室值班记录 tab -->
        <el-tab-pane label="消防控制室值班记录" v-loading="loading3" name="dutyRecord">
          <div class="header">
            <el-form :inline="true" :model="searchRecord" class="demo-form-inline">
              <el-form-item label="消防控制室名称">
                <el-input v-model="searchRecord.roomName" placeholder="请输入消防控制室名称" size="small"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="initDutyRecord">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="recordList" stripe style="width: 100%" height="100%">
              <el-table-column prop="roomName" label="消防室控制室名称" show-overflow-tooltip width="150" align="center">
                <template slot-scope="scope">
                  <el-button type="text" size="default" @click="open('record',scope.row)">{{scope.row.roomName}}</el-button>
                </template>
              </el-table-column>
              <el-table-column prop="personName" label="值班管理人员" show-overflow-tooltip width="100" align="center">
              </el-table-column>
              <el-table-column prop="dutyTime" label="值班日期" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                  <span>{{scope.row.dutyTime|timeFormat}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="dutyType" label="值班班次" show-overflow-tooltip width="100" align="center">
                <template slot-scope="scope">
                  <el-tag type="primary" size="normal" v-show="scope.row.dutyType===1">早班</el-tag>
                  <el-tag type="primary" size="normal" v-show="scope.row.dutyType===2">中班</el-tag>
                  <el-tag type="primary" size="normal" v-show="scope.row.dutyType===3">晚班</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="消防控制室值班情况" show-overflow-tooltip width="100" align="center">
                <el-table-column label="火灾报警控制器运行情况" show-overflow-tooltip width="100" align="center">
                  <el-table-column prop="deviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
                    <template slot-scope="scope">
                      <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                      <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="checkTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
                  </el-table-column>
                </el-table-column>
                <el-table-column label="控制室内其他消防系统运行情况" show-overflow-tooltip width="100" align="center">
                  <el-table-column prop="deviceName" label="设备名称" show-overflow-tooltip width="100" align="center">
                  </el-table-column>
                  <el-table-column prop="otherControlStatus" label="控制状态" show-overflow-tooltip width="100" align="center">
                    <template slot-scope="scope">
                      <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===1">电动</el-tag>
                      <el-tag type="primary" size="normal" v-show="scope.row.deviceRun===0">手动</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="otherDeviceRun" label="设备运行情况" show-overflow-tooltip width="100" align="center">
                    <template slot-scope="scope">
                      <el-tag type="success" size="normal" v-show="scope.row.deviceRun===1">异常</el-tag>
                      <el-tag type="danger" size="normal" v-show="scope.row.deviceRun===0">正常</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column prop="otherTrouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
                  </el-table-column>
                </el-table-column>
              </el-table-column>
              <el-table-column label="火灾报警器日常检查情况" show-overflow-tooltip width="100" align="center">
                <el-table-column prop="checkContent" label="检查内容" show-overflow-tooltip width="100" align="center">
                </el-table-column>
                <el-table-column prop="checkTime" label="检查时间" show-overflow-tooltip width="100" align="center">
                  <template slot-scope="scope">
                    <span>{{scope.row.checkTime|timeFormat}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="checkPerson" label="检查人" show-overflow-tooltip width="100" align="center">
                </el-table-column>
                <el-table-column prop="trouble" label="故障及处理情况" show-overflow-tooltip width="100" align="center">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeRecordPage" :current-page.sync="recordPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="recordTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 设施维护保养报告 tab -->
        <el-tab-pane label="设施维护保养报告" v-loading="loading4" name="deviceReport">
          <div class="header">
            <el-form :inline="true" :model="searchReport" class="demo-form-inline">
              <el-form-item label="类型">
                <el-select v-model="searchReport.type" placeholder="请选择类型" size="small" clearable>
                  <el-option v-for="(item,i) in maintainTypeList" :key="i" :label="item.text" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="initDeviceReport">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="reportList" stripe style="width: 100%" height="100%">
              <el-table-column prop="conclusion" label="维护保养结论" show-overflow-tooltip width="140">
              </el-table-column>
              <el-table-column prop="type" label="维护类型" show-overflow-tooltip width="120">
                <template slot-scope="scope">
                  <span v-show="scope.row.type===310">个人维护</span>
                  <span v-show="scope.row.type===311">外部单位维护</span>
                </template>
              </el-table-column>
              <el-table-column prop="month" label="月份" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.month|monthFormat}}
                </template>
              </el-table-column>
              <el-table-column prop="fireAutoAlarm" label="火灾自动报警系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.fireAutoAlarm===306">正常运行</span>
                  <span v-show="scope.row.fireAutoAlarm===307">故障运行</span>
                  <span v-show="scope.row.fireAutoAlarm===308">瘫痪停用</span>
                  <span v-show="scope.row.fireAutoAlarm===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="autoWaterFire" label="自动喷水灭火系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.autoWaterFire===306">正常运行</span>
                  <span v-show="scope.row.autoWaterFire===307">故障运行</span>
                  <span v-show="scope.row.autoWaterFire===308">瘫痪停用</span>
                  <span v-show="scope.row.autoWaterFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="frothFire" label="泡沫灭火系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.frothFire===306">正常运行</span>
                  <span v-show="scope.row.frothFire===307">故障运行</span>
                  <span v-show="scope.row.frothFire===308">瘫痪停用</span>
                  <span v-show="scope.row.frothFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="gasFire" label="气体灭火系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.gasFire===306">正常运行</span>
                  <span v-show="scope.row.gasFire===307">故障运行</span>
                  <span v-show="scope.row.gasFire===308">瘫痪停用</span>
                  <span v-show="scope.row.gasFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="dryPowerFire" label="干粉灭火系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.dryPowerFire===306">正常运行</span>
                  <span v-show="scope.row.dryPowerFire===307">故障运行</span>
                  <span v-show="scope.row.dryPowerFire===308">瘫痪停用</span>
                  <span v-show="scope.row.dryPowerFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="waterMistFire" label="细水雾灭火系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.waterMistFire===306">正常运行</span>
                  <span v-show="scope.row.waterMistFire===307">故障运行</span>
                  <span v-show="scope.row.waterMistFire===308">瘫痪停用</span>
                  <span v-show="scope.row.waterMistFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="dischargeSmoke" label="排烟系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.dischargeSmoke===306">正常运行</span>
                  <span v-show="scope.row.dischargeSmoke===307">故障运行</span>
                  <span v-show="scope.row.dischargeSmoke===308">瘫痪停用</span>
                  <span v-show="scope.row.dischargeSmoke===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="preventSmoke" label="防烟系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.preventSmoke===306">正常运行</span>
                  <span v-show="scope.row.preventSmoke===307">故障运行</span>
                  <span v-show="scope.row.preventSmoke===308">瘫痪停用</span>
                  <span v-show="scope.row.preventSmoke===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="roomInsideWater" label="室内消防给水系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.roomInsideWater===306">正常运行</span>
                  <span v-show="scope.row.roomInsideWater===307">故障运行</span>
                  <span v-show="scope.row.roomInsideWater===308">瘫痪停用</span>
                  <span v-show="scope.row.roomInsideWater===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="roomOutWater" label="室外消防给水系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.roomOutWater===306">正常运行</span>
                  <span v-show="scope.row.roomOutWater===307">故障运行</span>
                  <span v-show="scope.row.roomOutWater===308">瘫痪停用</span>
                  <span v-show="scope.row.roomOutWater===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="light" label="疏散指示标志和应急照明" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.light===306">正常运行</span>
                  <span v-show="scope.row.light===307">故障运行</span>
                  <span v-show="scope.row.light===308">瘫痪停用</span>
                  <span v-show="scope.row.light===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="electricity" label="消防供电配电" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.electricity===306">正常运行</span>
                  <span v-show="scope.row.electricity===307">故障运行</span>
                  <span v-show="scope.row.electricity===308">瘫痪停用</span>
                  <span v-show="scope.row.electricity===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="fireBroadcast" label="火灾应急广播系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.fireBroadcast===306">正常运行</span>
                  <span v-show="scope.row.fireBroadcast===307">故障运行</span>
                  <span v-show="scope.row.fireBroadcast===308">瘫痪停用</span>
                  <span v-show="scope.row.fireBroadcast===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="elevator" label="消防电梯" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.elevator===306">正常运行</span>
                  <span v-show="scope.row.elevator===307">故障运行</span>
                  <span v-show="scope.row.elevator===308">瘫痪停用</span>
                  <span v-show="scope.row.elevator===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="other" label="其他系统" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.other===306">正常运行</span>
                  <span v-show="scope.row.other===307">故障运行</span>
                  <span v-show="scope.row.other===308">瘫痪停用</span>
                  <span v-show="scope.row.other===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="preventFire" label="防火分隔" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.preventFire===306">正常运行</span>
                  <span v-show="scope.row.preventFire===307">故障运行</span>
                  <span v-show="scope.row.preventFire===308">瘫痪停用</span>
                  <span v-show="scope.row.preventFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="cutFire" label="灭火器" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.cutFire===306">正常运行</span>
                  <span v-show="scope.row.cutFire===307">故障运行</span>
                  <span v-show="scope.row.cutFire===308">瘫痪停用</span>
                  <span v-show="scope.row.cutFire===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="deviceFunction" label="控制室联动控制设备功能" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.deviceFunction===306">正常运行</span>
                  <span v-show="scope.row.deviceFunction===307">故障运行</span>
                  <span v-show="scope.row.deviceFunction===308">瘫痪停用</span>
                  <span v-show="scope.row.deviceFunction===309">拆除</span>
                </template>
              </el-table-column>
              <el-table-column prop="exits" label="消防通道" show-overflow-tooltip width="140">
                <template slot-scope="scope">
                  <span v-show="scope.row.exits===306">正常运行</span>
                  <span v-show="scope.row.exits===307">故障运行</span>
                  <span v-show="scope.row.exits===308">瘫痪停用</span>
                  <span v-show="scope.row.exits===309">拆除</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeReportPage" :current-page.sync="reportPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="reportTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
        <!-- 责令整改 tab -->
        <el-tab-pane label="责令整改" v-loading="loading5" name="correct">
          <div class="header">
            <el-form :inline="true" :model="searchCorrect" class="demo-form-inline">
              <el-form-item label="单位名称">
                <el-select v-model="searchCorrect.unitId" placeholder="请选择所在单位" clearable filterable remote :remote-method="remoteMethod" size="small">
                  <el-option v-for="(item,i) in unitOptions" :key="i" :label="item.unitName" :value="item.id">
                    <span style="float: left">{{ item.unitName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px;margin-left:3px;">{{ item.address }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="initCorrect">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="content">
            <el-table :data="correctList" stripe style="width: 100%" height="100%">
              <el-table-column prop="unitName" label="单位名称" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="createTime" label="通知发布时间" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{scope.row.createTime|timeFormat}}
                </template>
              </el-table-column>
              <el-table-column label="操作" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-tooltip content="查看" placement="top" effect="dark">
                    <el-button type="primary" circle icon="el-icon-view" size="small" @click="open('correct',scope.row)"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="footer">
            <el-pagination @current-change="changeCorrecttPage" :current-page.sync="correctPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="correctTotal">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 防火巡检 -->
    <el-drawer class="inspect" :title="inspect.checkResult===1?'自我检查合格':'自我检查不合格'" :visible.sync="inspectShow" direction="rtl" :before-close="handleClose" size="33%">
      <el-form label-position="left" inline class="drawer-form" label-width="320px">
        <el-form-item label="检查结果">
          <span>{{inspect.checkResult===1?'自我检查合格':'自我检查不合格'}}</span>
        </el-form-item>
        <el-form-item label="巡查人">
          <span>{{inspect.patrolPerson}}</span>
        </el-form-item>
        <el-form-item label="核查人">
          <span>{{inspect.checkPerson}}</span>
        </el-form-item>
        <el-form-item label="安全主管">
          <span>{{inspect.safeHead}}</span>
        </el-form-item>
        <el-form-item label="用火、用电有无违章情况">
          <el-tag type="success" size="normal" v-show="inspect.isFire===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isFire===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全出口、安全疏散通道是否畅通">
          <el-tag type="success" size="normal" v-show="inspect.isExit===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isExit===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全疏散疏散指示标志、应急照明是否完好">
          <el-tag type="success" size="normal" v-show="inspect.isLight===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isLight===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防设施、器材和消防安全标志是否到位、完整">
          <el-tag type="success" size="normal" v-show="inspect.isFlag===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isFlag===0">是</el-tag>
        </el-form-item>
        <el-form-item label="常闭式防火门是否处于关闭状态">
          <el-tag type="success" size="normal" v-show="inspect.isDoor===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isDoor===0">是</el-tag>
        </el-form-item>
        <el-form-item label="防火卷帘下是否堆放物品影响使用">
          <el-tag type="success" size="normal" v-show="inspect.isThing===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isThing===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防安全重点部位的人员在岗情况">
          <el-tag type="success" size="normal" v-show="inspect.isPerson===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isPerson===0">否</el-tag>
        </el-form-item>
        <el-form-item label="其他消防安全情况">
          <el-tag type="success" size="normal" v-show="inspect.isOthers===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="inspect.isOthers===0">否</el-tag>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- NFC巡检 -->
    <el-drawer class="nfc" :title="nfc.checkResult===1?'自我检查合格':'自我检查不合格'" :visible.sync="nfcShow" direction="rtl" :before-close="handleClose" size="33%">
      <el-form label-position="left" inline class="drawer-form" label-width="320px">
        <el-form-item label="检查结果">
          <span v-show="nfc.checkResult===0">自我检查合格</span>
          <span v-show="nfc.checkResult===1">现场整改</span>
          <span v-show="nfc.checkResult===2">限期整改</span>
        </el-form-item>
        <el-form-item label="巡查人">
          <span>{{nfc.patrolPerson}}</span>
        </el-form-item>
        <el-form-item label="核查人">
          <span>{{nfc.checkPerson}}</span>
        </el-form-item>
        <el-form-item label="安全主管">
          <span>{{nfc.safeHead}}</span>
        </el-form-item>
        <el-form-item label="用火、用电有无违章情况">
          <el-tag type="success" size="normal" v-show="nfc.isFire===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isFire===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全出口、安全疏散通道是否畅通">
          <el-tag type="success" size="normal" v-show="nfc.isExit===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isExit===0">是</el-tag>
        </el-form-item>
        <el-form-item label="安全疏散疏散指示标志、应急照明是否完好">
          <el-tag type="success" size="normal" v-show="nfc.isLight===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isLight===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防设施、器材和消防安全标志是否到位、完整">
          <el-tag type="success" size="normal" v-show="nfc.isFlag===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isFlag===0">是</el-tag>
        </el-form-item>
        <el-form-item label="常闭式防火门是否处于关闭状态">
          <el-tag type="success" size="normal" v-show="nfc.isDoor===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isDoor===0">是</el-tag>
        </el-form-item>
        <el-form-item label="防火卷帘下是否堆放物品影响使用">
          <el-tag type="success" size="normal" v-show="nfc.isThing===1">否</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isThing===0">是</el-tag>
        </el-form-item>
        <el-form-item label="消防安全重点部位的人员在岗情况">
          <el-tag type="success" size="normal" v-show="nfc.isPerson===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isPerson===0">否</el-tag>
        </el-form-item>
        <el-form-item label="其他消防安全情况">
          <el-tag type="success" size="normal" v-show="nfc.isOthers===1">是</el-tag>
          <el-tag type="danger" size="normal" v-show="nfc.isOthers===0">否</el-tag>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 消防控制室值班记录 -->
    <el-drawer class="record" title="核查时间" :visible.sync="recordShow" direction="rtl" :before-close="handleClose" size="33%">
      <el-form label-position="left" inline class="drawer-form" label-width="150px">
        <el-form-item label="消防室控制室名称">
          <span>{{record.roomName}}</span>
        </el-form-item>
        <el-form-item label="值班管理人员">
          <span>{{record.personName}}</span>
        </el-form-item>
        <el-form-item label="值班日期">
          <span>{{record.dutyTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="值班班次">
          <el-tag type="primary" size="normal" v-show="record.dutyType===1">早班</el-tag>
          <el-tag type="primary" size="normal" v-show="record.dutyType===2">中班</el-tag>
          <el-tag type="primary" size="normal" v-show="record.dutyType===3">晚班</el-tag>
        </el-form-item>
        <div class="title">
          一、火灾报警控制器运行情况
        </div>
        <el-form-item label="设备运行情况">
          <el-tag type="success" size="normal" v-show="record.deviceRun===1">异常</el-tag>
          <el-tag type="danger" size="normal" v-show="record.deviceRun===0">正常</el-tag>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.checkTrouble" readonly></el-input>
        </el-form-item>
        <div class="title">
          二、控制室内其他消防系统运行情况
        </div>
        <el-form-item label="设备名称">
          <span>{{record.deviceName}}</span>
        </el-form-item>
        <el-form-item label="控制状态">
          <el-tag type="primary" size="normal" v-show="record.otherControlStatus===1">电动</el-tag>
          <el-tag type="primary" size="normal" v-show="record.otherControlStatus===0">手动</el-tag>
        </el-form-item>
        <el-form-item label="设备运行情况">
          <el-tag type="success" size="normal" v-show="record.otherDeviceRun===1">异常</el-tag>
          <el-tag type="danger" size="normal" v-show="record.otherDeviceRun===0">正常</el-tag>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.otherTrouble" readonly></el-input>
        </el-form-item>
        <div class="title">
          三、火灾报警器日常检查情况
        </div>
        <el-form-item label="检查内容">
          <span>{{record.checkContent}}</span>
        </el-form-item>
        <el-form-item label="检查人">
          <span>{{record.checkPerson}}</span>
        </el-form-item>
        <el-form-item label="检查时间">
          <span>{{record.checkTime|timeFormat}}</span>
        </el-form-item>
        <el-form-item label="故障及处理情况">
          <el-input type="textarea" :rows="3" v-model="record.trouble" readonly></el-input>
        </el-form-item>
      </el-form>
    </el-drawer>
    <!-- 责令整改内容 -->
    <el-dialog title="责令整改通知" :visible.sync="correctShow" width="50%">
      <table class="unit-attr">
        <tr>
          <td colspan="12">根据《中华人民共和国消防法》第五十三条规定，我所于_对你单位（场所）进行消防监督检查，发现你单位（场所）存 在下列第_项消防安全违法行为，现责令改正：</td>
        </tr>
        <tr>
          <td colspan="12">
            1.<el-radio :disabled="true" v-model="correct.option1" :label="0">未制定消防安全制度</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            2.<el-radio-group v-model="correct.option2">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'未组织'},{id:1,label:'防火检查'},{id:2,label:'消防安全教育培训'},{id:3,label:'消防演练'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            3.<el-radio-group v-model="correct.option3">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭疏散通道、安全出口'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            4.<el-radio-group v-model="correct.option4">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'占用'},{id:1,label:'堵塞'},{id:2,label:'封闭消防车通道，妨碍消防车通行'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            5.<el-radio-group v-model="correct.option5">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'埋压'},{id:1,label:'圈占'},{id:2,label:'遮挡消防栓'},{id:3,label:'占用防火间距'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            6.<el-radio-group v-model="correct.option6">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'室内消防栓'},{id:1,label:'灭火器'},{id:2,label:'疏散指示标志'},{id:3,label:'应急照明未保持完好有效'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            7.<el-radio :disabled="true" v-model="correct.option7" :label="0">人员密集场所外墙门窗上设置影响逃生、灭火救援的障碍物</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            8.<el-radio-group v-model="correct.option8">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'违反消防安全规定进入'},{id:1,label:'生产'},{id:2,label:'储存易燃易爆危险品场所'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            9.<el-radio :disabled="true" v-model="correct.option9" :label="0">违反规定使用明火作业</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            10.<el-radio-group v-model="correct.option10">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'在具有火灾、爆炸危险的场所'},{id:1,label:'吸烟'},{id:2,label:'使用明火'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            11.<el-radio-group v-model="correct.option11">
              <el-radio :disabled="true" v-for="(item,i) in [{id:0,label:'生产'},{id:1,label:'储存'},{id:2,label:'经营易燃易爆危险品的场所与居住场所设置在同一建筑物内的；'}]" :key="i" :label="item.id">
                {{item.label}}
              </el-radio>
            </el-radio-group>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            12.<el-radio :disabled="true" v-model="correct.option12" :label="0">未对建筑消防设施定期进行维修保养</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="12">
            13.<el-radio :disabled="true" v-model="correct.option13" :label="0">其他消防安全违法行为和火灾隐患</el-radio>
          </td>
        </tr>
        <tr colspan="12" v-if="correct.remark">
          <td colspan="4">备注</td>
          <td colspan="8">
            {{correct.remark}}
          </td>
        </tr>
        <tr>
          <td colspan="6" class="center">被检查单位(场所)签收:</td>
          <td colspan="6" class="center nopadding">
            <el-button type="primary" size="default" @click="open('sign')" style="width:100%;height:100%;border-radius:0;">预览</el-button>
          </td>
        </tr>
        <tr>
          <td colspan="12">一式两份，一份交被检查单位(场所)，一份存档。</td>
        </tr>
      </table>
    </el-dialog>
    <!-- 电子签名 -->
    <el-dialog title="电子签名" :visible.sync="signShow" width="50%" class="sign">
      <img :src="correct.headName" alt="" width="100%">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      inspectPage: 1,
      searchInspect: {},
      inspectTotal: 0,
      inspect: {},
      inspectList: [],
      nfcPage: 1,
      searchNfc: {},
      nfcTotal: 0,
      nfc: {},
      NFCList: [],
      recordPage: 1,
      searchRecord: {},
      recordTotal: 0,
      record: {},
      recordList: [],
      reportPage: 1,
      searchReport: {},
      reportTotal: 0,
      reportList: [],
      correct: {},
      correctPage: 1,
      searchCorrect: {},
      correctTotal: 0,
      correctList: [],
      tabName: 'inspect',
      loading1: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      inspectShow: false,
      nfcShow: false,
      recordShow: false,
      correctShow: false,
      signShow: false,
      maintainTypeList: [],
      resultText: '',
      unitOptions: []
    }
  },
  created () {
    this.initFireInpect()
  },
  methods: {
    changeTab (tab, event) {
      switch (this.tabName) {
        case 'inspect':
          this.initFireInpect()
          break
        case 'NFC':
          this.initNFC()
          break
        case 'dutyRecord':
          this.initDutyRecord()
          break
        case 'deviceReport':
          this.initDeviceReport()
          this.getMaintainType()
          break
        case 'correct':
          this.initCorrect()
          break
      }
    },
    // 防火巡检
    async initFireInpect () {
      const data = {}
      if (this.searchInspect.checkPerson) {
        data.checkPerson = this.searchInspect.checkPerson
      }
      if (this.searchInspect.patrolPerson) {
        data.patrolPerson = this.searchInspect.patrolPerson
      }
      data.pageNo = this.inspectPage
      data.pageSize = 10
      data.checkResult = 1
      this.loading1 = true
      const { data: result } = await this.$axios.get('/firePatrol/list', { params: data })
      if (result.code === 200) {
        this.inspectList = result.data.result
        this.inspectTotal = result.data.total
        this.loading1 = false
      } else {
        this.loading1 = false
        this.$message.error(result.msg)
      }
    },
    // NFC巡检
    async initNFC () {
      const data = {}
      if (this.searchNfc.checkPerson) {
        data.checkPerson = this.searchNfc.checkPerson
      }
      if (this.searchNfc.patrolPerson) {
        data.patrolPerson = this.searchNfc.patrolPerson
      }
      data.pageNo = this.nfcPage
      data.pageSize = 10
      data.checkResult = 1
      this.loading2 = true
      const { data: result } = await this.$axios.get('/nfcPatrol/list', { params: data })
      if (result.code === 200) {
        this.NFCList = result.data.result
        this.nfcTotal = result.data.total
        this.loading2 = false
      } else {
        this.loading2 = false
        this.$message.error(result.msg)
      }
    },
    // 消防控制室值班记录
    async initDutyRecord () {
      const data = {}
      if (this.searchRecord.roomName) {
        data.roomName = this.searchRecord.roomName
      }
      data.pageNo = this.recordPage
      data.pageSize = 10
      this.loading3 = true
      const { data: result } = await this.$axios.get('/fireControlDutyRecord/list', { params: data })
      if (result.code === 200) {
        this.recordList = result.data.result
        this.recordTotal = result.data.total
        this.loading3 = false
      } else {
        this.loading3 = false
        this.$message.error(result.msg)
      }
    },
    // 设备保养报告
    async initDeviceReport () {
      const data = {}
      if (this.searchReport.type) {
        data.type = this.searchReport.type
      }
      data.pageNo = this.reportPage
      data.pageSize = 10
      this.loading4 = true
      const { data: result } = await this.$axios.get('/deviceProtectReport/list', { params: data })
      if (result.code === 200) {
        this.reportList = result.data.result
        this.reportTotal = result.data.total
        this.loading4 = false
      } else {
        this.loading4 = false
        this.$message.error(result.msg)
      }
    },
    // 初始化表格
    async initCorrect () {
      const data = {}
      if (this.searchCorrect.unitId > 0) {
        data.unitId = this.searchCorrect.unitId
      }
      data.pageNo = this.correctPage
      data.pageSize = 10
      this.loading5 = true
      const { data: result } = await this.$axios.get('/rectifyNotice/list', { params: data })
      if (result.code === 200) {
        this.correctList = result.data.result
        this.correctTotal = result.data.total
        this.loading5 = false
      } else {
        this.$message.error(result.msg)
      }
    },
    // 分页
    changeInspectPage (val) {
      this.inspectPage = val
      this.initFireInpect()
    },
    changeNFCPage (val) {
      this.nfcPage = val
      this.initNFC()
    },
    changeRecordPage (val) {
      this.recordPage = val
      this.initDutyRecord()
    },
    changeReportPage (val) {
      this.reportPage = val
      this.initDeviceReport()
    },
    changeCorrecttPage (val) {
      this.correctPage = val
      this.initCorrect()
    },
    open (flag, row) {
      switch (flag) {
        case 'inspect':
          this.inspectShow = true
          this.inspect = JSON.parse(JSON.stringify(row))
          break
        case 'NFC':
          switch (row.checkResult) {
            case 0:
              this.resultText = '自我检查合格'
              break
            case 1:
              this.resultText = '现场整改'
              break
            case 2:
              this.resultText = '限期整改'
              break
          }
          this.nfcShow = true
          this.nfc = JSON.parse(JSON.stringify(row))
          break
        case 'record':
          this.recordShow = true
          this.record = JSON.parse(JSON.stringify(row))
          break
        case 'correct':
          this.correctShow = true
          this.correct = row
          break
        case 'sign':
          if (!this.correct.headName) {
            this.$message.error('暂无签名')
          } else {
            this.signShow = true
          }
          break
      }
    },
    // 查找维护类型
    async getMaintainType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'deviceProtectType' } })
      if (result.code === 200) {
        this.maintainTypeList = result.data
      }
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    async remoteMethod (query) {
      const { data: result } = await this.$axios.get('/unit/listAllUnit', { params: { unitName: query } })
      if (result.code === 200) {
        this.unitOptions = result.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
.stat-container {
  height: 100%;
  .stat-tab {
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    .el-tabs {
      width: 100%;
      height: 100%;
      background-color: rgba(30, 74, 115, 0.5);
      border: none;
      ::v-deep .el-tabs__header {
        background-color: rgba(7, 63, 112, 0.6);
        .el-tabs__item {
          color: #fff;
        }
        .is-active {
          background-color: rgba(30, 74, 115, 0.9);
          color: #409eff;
          border-right-color: #dcdfe6;
          border-left-color: #dcdfe6;
        }
      }
      .el-tab-pane {
        height: 100%;
        padding: 5px 5px 0 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
        overflow: hidden;
        background-color: transparent;
        .header {
          flex: 1;
          display: flex;
          align-items: center;
          .el-button {
            margin-right: auto;
          }
          .el-form {
            margin-right: auto;
            height: 40px;
            ::v-deep .el-form-item__label {
              color: #fff;
            }
            ::v-deep .el-input__inner {
              background-color: rgba(7, 104, 159, 0.15);
              color: #fff;
            }
          }
        }
        .content {
          flex: 10;
          box-sizing: border-box;
          .el-table {
            background-color: transparent;
            ::v-deep tr,
            ::v-deep th {
              background-color: transparent;
              color: #fff;
              .el-table__expand-icon {
                color: #fff;
              }
            }
            ::v-deep .el-table--enable-row-hover,
            ::v-deep .hover-row > td,
            ::v-deep tr:hover > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
            ::v-deep .el-table__row--striped > td {
              background-color: rgba(7, 104, 159, 0.15) !important;
            }
          }
          ::v-deep .el-table__expanded-cell {
            background-color: transparent;
          }
        }
        .footer {
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: auto;
          .el-pagination {
            margin-right: auto;
            color: #fff;
            background-color: transparent;
            ::v-deep .el-pagination__total,
            ::v-deep .el-pagination__jump {
              color: #fff;
            }
            ::v-deep .btn-prev,
            ::v-deep .btn-next {
              background-color: transparent;
              color: #fff;
            }
            ::v-deep .el-pager li {
              background-color: transparent !important;
            }
            ::v-deep .btn-quicknext {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.inspect,
.nfc {
  .drawer-form {
    font-size: 0;
    padding: 10px 40px;
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      width: 100%;
      ::v-deep  label {
        color: #99a9bf !important;
        float: left;
      }
      ::v-deep .el-form-item__content {
        float: left;
      }
    }
  }
}
.record {
  ::v-deep .el-drawer__body {
    overflow-y: scroll;
    .drawer-form {
      font-size: 0;
      padding: 10px 40px;
      .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 100%;
        ::v-deep  label {
          color: #99a9bf !important;
          float: left;
        }
        ::v-deep .el-form-item__content {
          float: right;
          width: calc(100% - 150px);
        }
      }
      .title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        box-sizing: border-box;
        background-color: #f5f5f5;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        font-size: 16px;
        text-align: left;
        margin-top: 10px;
      }
    }
  }
}
::v-deep  :focus {
  outline: 0;
}

.unit-attr {
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  border-collapse: collapse;
  table-layout: fixed;
  word-break: break-all;
  white-space: normal;
  td {
    height: 45px;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    padding: 5px;
    text-align: left;
    color: #fff;
    .el-radio {
      color: #fff;
      ::v-deep .el-radio__label {
        padding-left: 5px;
      }
      ::v-deep .is-disabled {
        .el-radio__inner::after {
          background-color: #409eff;
          width: 6px;
          height: 6px;
        }
      }
    }
    .el-input-number {
      width: 100%;
      height: 100%;
      ::v-deep .el-input-number__decrease,
      ::v-deep .el-input-number__increase {
        background-color: rgba(7, 104, 159, 0.15);
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      ::v-deep .el-input {
        height: 100%;
        .el-input__inner {
          height: 100%;
        }
      }
    }
  }
  .nopadding {
    padding: 0;
  }
  .center {
    text-align: center;
  }
}
.el-table th.gutter {
  display: table-cell !important;
}

.el-table colgroup.gutter {
  display: table-cell !important;
}
</style>
